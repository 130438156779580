<template>
  <div id="app">
    <div class="p-5">
      <Wallet />
    </div>
  </div>
</template>

<script>
import Wallet from "./components/eosio/Wallet.vue";

export default {
  name: "App",
  components: {
    Wallet,
  }
};
</script>
<style>
.btn {
  font-family: Avenir, Helvetica, Arial, sans-serif !important;
  text-transform: uppercase !important;
  text-transform: uppercase !important;
  letter-spacing: 2px;
  font-size: 14px !important;
  font-weight: bold !important;
}
</style>
