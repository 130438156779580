import { showConnect, isStacksWalletInstalled } from "@stacks/connect"

export default {
  namespaced: true,
  state: {
    isStacksWalletAvailable: null,
    stacksWalletSession: null,
    message: null,
    stxAddress: null,
    isLoading: false
  },
  mutations: {
    setIsStacksWalletAvailable(state, isAvailable) {
      state.isStacksWalletAvailable = isAvailable;
    },
    setStacksWalletSession(state, session) {
      state.stacksWalletSession = session;
    },
    setStxAddress(state, address) {
      state.stxAddress = address
    },
    setLoading(state, loading) {
      state.isLoading = loading
    },
    toggleLoading(state) {
      state.isLoading = !state.isLoading
    },
    setMessage(state, message) {
      state.message = {
        type: 'info',
        message
      }
    }
  },
  actions: {
    checkStacksWalletInstalled({commit}) {
      const isWalletInstalled = isStacksWalletInstalled()
      commit('setIsStacksWalletAvailable', isWalletInstalled)
    },
    disconnectStacksWallet({commit, state}) {
      commit('toggleLoading')
      commit('setStacksWalletSession', null)
      commit('setStxAddress', null)
      state.stacksWalletSession?.userSession?.signUserOut()
      commit('toggleLoading')
    },
    connectStacksWallet({commit}) {
      commit('toggleLoading')
      showConnect({
        onFinish: (session) => {
          commit('setStacksWalletSession', session)
          commit('setStxAddress', session.authResponsePayload.profile.stxAddress)
          commit('toggleLoading')
        },
        onCancel: () => {
          commit('setMessage', 'use cancel the action')
          commit('toggleLoading')
        },
        appDetails: {
          name: "Hirevibes",
          icon: "https://app.hirevibes.io/static/img/hv-logo.9662996.svg"
        }
      })
    }
  }
}