import { initDefaultAccessContext } from "eos-transit";
import anchorlink from "eos-transit-anchorlink-provider";


const appName = 'hirevibes.com'
const migrationAccountName = 'hvtstxmigrat'
import { getNetwork  } from "../../helper";

const networks = {
  "mainnet": {
    host: "eos.greymass.com",
    port: 443,
    protocol: "https",
    chainId:
      "aca376f206b8fc25a6ed44dbdc66547c36c6c33e3a119ffbeaef943642f0e906",
  },

  "testnet": {
    host: "jungle4.cryptolions.io",
    port: 443,
    protocol: "https",
    chainId:
      "73e4385a2708e6d7048834fbc1079f2fabb17b3c125b146af438971e90716c4d",
  }
}



export default {
  namespaced: true,
  state: {
    ctx: null,
    providers: [],
    currentWallet: null,
    accountInfo: null,
    tokenBalance: null,
    messages: null,
    authInfo: null,
    response: null,
    isLoading: false
  },

  mutations: {
    setCtx(state, ctx) {
      state.ctx = ctx
    },
    setProviders(state, providers) {
      state.providers = providers;
    },
    setCurrentWallet(state, wallet) {
      state.currentWallet = wallet
    },
    setAccountInfo(state, accountInfo) {
      state.accountInfo = accountInfo;
    },
    setTokenBalance(state, balance) {
      state.tokenBalance = balance;
    },
    setAuthInfo(state, auth) {
      state.authInfo = auth
    },
    setResponse(state, response) {
      state.response = response
    },
    toggleLoading(state) {
      state.isLoading = !state.isLoading
    },
    setLoading(state, loading) {
      state.isLoading = loading
    },
    setError(state, message) {
      state.messages = {
        type: 'error',
        message
      }
    },
    resetMessage(state) {
      state.messages = null
    }
  },
  actions: {
    initContext({ commit }) {
      try {
        commit('resetMessage')
        const ctx = initDefaultAccessContext({
          appName,
          network: networks[getNetwork()],
          walletProviders: [
            anchorlink(
              Math.random().toString(36).substring(2, 15) +
              Math.random().toString(36).substring(2, 15),
              {}
            ),
          ],
        });
        if (!ctx) {
          commit('setError', 'unable to initialize eosio wallets!');
          return;
        }
        commit('setCtx', ctx);
        commit('setProviders', ctx.getWalletProviders());
      } catch (e) {
        commit('setError', e?.message);
      }
    },
    async initWallet({ commit, dispatch, state }, provider) {
      try {
        commit('resetMessage')
        const wallet = state.ctx.initWallet(provider);
        commit('setCurrentWallet', wallet);
        dispatch('login');
      } catch (e) {
        commit('setError', e?.message);
      }
    },
    async login({ commit, state, dispatch }) {
      try {
        commit('resetMessage')
        const accountInfo = await state.currentWallet.login();
        commit('setAccountInfo', accountInfo);
        commit('setAuthInfo', state.currentWallet.auth)
        dispatch('getTokenBalance', {
          accountName: state.currentWallet.auth.accountName,
          symbol: 'HVT'
        })
      } catch (e) {
        commit('setError', e?.message);
      }
    },
    async getTokenBalance({ state, commit }, params) {
      try {
        commit('resetMessage')
        const symbol = params.symbol ?? 'HVT'
        const tokenBalance = await state.currentWallet.eosApi.rpc.get_currency_balance(
          'hirevibeshvt',
          params.accountName,
          symbol
        );
        if (!tokenBalance || !tokenBalance.length) {
          commit('setTokenBalance', {
            amount: 0,
            symbol
          })
          return
        }
        const parts = tokenBalance[0].split(' ')
        commit('setTokenBalance', {
          amount: parts[0],
          symbol
        })
      } catch (e) {
        commit('setError', e.message);
      }
    },
    async transferToken({ state, commit, dispatch }, props) {
      try {
        commit('resetMessage')
        commit('setLoading', true)
        const tx = await state.currentWallet.eosApi
          .transact({
            actions: [
              {
                account: 'hirevibeshvt',
                name: 'transfer',
                authorization: [
                  {
                    actor: props.auth.accountName,
                    permission: props.auth.permission
                  }
                ],
                data: {
                  from: props.auth.accountName,
                  to: migrationAccountName,
                  quantity: `${props.amount.toFixed(4)} HVT`,
                  memo: props.memo
                }
              }
            ]
          },
            {
              broadcast: true,
              blocksBehind: 3,
              expireSeconds: 60
            }
          )
        commit('setResponse', tx)
        dispatch('getTokenBalance', { accountName: props.auth.accountName })
      } catch (e) {
        commit('setError', e.message)
      } finally {
        commit('setLoading', false)
      }
    }
  }
}