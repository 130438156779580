<template>
  <div class="eosio-wallets">
    <div class="account-info" v-if="accountInfo">
      <div class="row">
        <div class="col-md-8 col-sm-12" v-if="tokenBalance">
          <div class="card">
            <div class="card-body">
              <h5 class="card-title">
                {{ getHvtBalance }}
                    <label class="text-muted">{{
                      tokenBalance.symbol
                    }}</label>
              </h5>
              <h6 class="card-subtitle mb-4 text-muted">{{ authInfo.accountName }}@{{ authInfo.permission }}</h6>
              <div
                v-if="messages && messages.type == 'error'"
                class="alert alert-danger"
              >
                {{ messages.message }}
              </div>
              <div v-if="response">
                <strong>Transaction:</strong>
                <pre class="text-wrap"><code class="text-wrap">{{response.transaction_id}}</code></pre>
                <a :href="getTxLink" target="_blank" class="btn btn-info text-white w-100"
                  >VIEW TRANSACTION</a
                >
              </div>
              <div v-else-if="getHvtBalance > 0">
                <div class="mb-3 row">
                  <div class="col-sm-12">
                    <div class="input-group">
                      <input
                        type="text"
                        class="form-control"
                        aria-describedby="btnGroupAddon"
                        v-model="amount"
                      />
                      <div class="input-group-text" id="btnGroupAddon">HVT</div>
                    </div>
                  </div>
                </div>
                <div class="mb-3 row">
                  <div class="col-sm-12">
                    <div class="btn-group w-100" role="group">
                      <button
                        v-for="step in steps"
                        :key="step"
                        type="button"
                        class="btn btn-outline-info"
                        :class="{ active: step === selectedStep }"
                        @click="changeStep(step)"
                      >
                        {{ step }}%
                      </button>
                    </div>
                  </div>
                </div>
                <div class="mb-3 row">
                  <div class="col-sm-12">
                    <div class="input-group" v-if="!getStxAddress">
                      <HiroWallet />
                    </div>
                    <div v-else class="input-group">
                      <code class="text-black form-control">{{ getStxAddress }}</code>
                      <span class="input-group-text">
                        <button @click="disconnectStacksWallet" type="button" class="btn-close" aria-label="Close"></button>
                      </span>
                    </div>
                  </div>
                </div>
                <button
                  v-if="isLoading"
                  class="btn btn-outline-success mt-3 w-100"
                  type="button"
                  disabled
                >
                  <span
                    class="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  Please wait...
                </button>
                <button
                  v-if="isValid && !isLoading"
                  type="submit"
                  @click="transfer"
                  class="btn btn-info text-white w-100"
                >
                MIGRATE TOKENS
                </button>
              </div>
              <div v-else>
                You do not have enough HVT token balance to migrate.
              </div>
            </div>
          </div>
          <div class="pt-5 text-center" v-if="response">
            <p><b>Congratulations!</b> You have successfully migrated your HVT!<br />Your brand new VIBES tokens will appear in your delegated Stacks wallet over the next 24 hours.</p>
            <p>If you have any issues receiving your tokens, please contact us with the transaction ID above, at: hello@hirevibes.io</p>
          </div>
        </div>
      </div>
    </div>
    <div class="row" v-else-if="providers">
      <div class="col-md-8 col-sm-12" v-for="provider in providers" :key="provider.id">
        <div class="card">
          <div class="card-body">
            <div class="text-center">
              <img
                :src="getProviderLogo(provider.id)"
                class="rounded"
                width="120"
                height="120"
                :alt="provider.id"
              />
              <h3>{{ provider.meta.shortName }}</h3>
              <p>{{ provider.meta.description }}</p>
              <button class="btn btn-info text-white" @click="connectProvider(provider)">
                Connect
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import achorWallet from "./../../assets/anchor-wallet.svg";
import scatterWallet from "./../../assets/scatter-wallet.svg";
import HiroWallet from "../stacks/HiroWallet.vue";
import { getNetwork } from './../../helper'
export default {
  components: { HiroWallet },
  data() {
    return {
      amount: 0,
      selectedStep: 100,
      steps: [25, 50, 75, 100],
    };
  },
  computed: {
    ...mapState("eosWallet", [
      "accountInfo",
      "providers",
      "tokenBalance",
      "authInfo",
      "messages",
      "response",
      "isLoading",
    ]),
    ...mapState("stxWallet", ["stxAddress"]),
    getStxAddress() {
      return this.stxAddress ? this.stxAddress[getNetwork()] : null;
    },
    isValid() {
      if (!this.getStxAddress) return false;
      console.log(this.amount, this.getHvtBalance);
      if (parseFloat(this.amount) > parseFloat(this.getHvtBalance))
        return false;
      return true;
    },
    getHvtBalance() {
      return this.tokenBalance ? this.tokenBalance.amount : 0;
    },
    getTxLink() {
      return `https://bloks.io/transaction/${this.response.transaction_id}`;
    },
  },
  watch: {
    tokenBalance: {
      deep: true,
      handler(newValue) {
        if (newValue) {
          this.amount = newValue.amount;
        }
      },
    },
  },
  methods: {
    ...mapActions('eosWallet', ['initContext', 'initWallet', 'transferToken']),
    ...mapActions('stxWallet', ['disconnectStacksWallet']),
    getProviderLogo(provider) {
      return provider === 'anchor-link' ? achorWallet : scatterWallet;
    },
    async connectProvider(provider) {
      await this.initWallet(provider);
    },
    changeStep(step) {
      this.selectedStep = step;
      this.amount = ((this.getHvtBalance * step) / 100 ?? 0).toFixed(4);
    },
    async transfer() {
      const props = {
        auth: this.authInfo,
        memo: this.getStxAddress,
        amount: parseFloat(this.amount ?? 0),
      };
      await this.transferToken(props);
    },
  },
  mounted() {
    this.initContext();
  },
};
</script>