<template>
  <div class="stacks-wallet w-100">
    <div v-if="isStacksWalletAvailable">
      <div v-if="!stxAddress">
        <button v-if="isLoading" class="btn btn-outline-info mt-3 w-100" type="button" disabled>
          <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          Connecting...
        </button>
        <button v-else type="button" class="btn btn-outline-info mt-3 w-100" @click="connectHiroWallet">Connect Stacks Wallet</button>
        <p class="mt-2 text-muted">Connect your stacks wallet to complete the migration.</p>
      </div>
    </div>
    <div v-else>
      Please wait, checking for a Stacks compatible wallet... <br />
      A Stacks compatible wallet is required to complete migration. Learn more <a href="https://www.stacks.co/explore/find-a-wallet" target="_blank">here</a>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  computed: {
    ...mapState("stxWallet", [
      "isStacksWalletAvailable",
      "stacksWalletSession",
      "stxAddress",
      "isLoading"
    ]),
  },
  methods: {
    ...mapActions("stxWallet", [
      "checkStacksWalletInstalled",
      "connectStacksWallet",
    ]),
    async connectHiroWallet() {
      await this.connectStacksWallet();
    },
  },
  mounted() {
    this.checkStacksWalletInstalled();
  },
};
</script>
