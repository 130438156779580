import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

import eosWallet from './modules/eos-wallet.module'
import stxWallet from './modules/stacks-wallet.module'

export default new Vuex.Store({
      modules: {
        eosWallet,
        stxWallet
      }
})